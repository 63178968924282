<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <CRow>
              <CCol md="12">
                    <h4>Balance By Top-up</h4> 
              </CCol> 
              <!--CCol md="5">                   
                   <CInput
                      label="Search"                     
                      horizontal
                      v-model="filter" 
                      @change="getFilterData"
                      placeholder="Type to Search" 
                    />            
              </CCol-->                
          </CRow>

        </CCardHeader><br/>
        <CCardBody>        
         <CRow>
              <CCol md="5">                   
                   <CInput
                      label="From Date"
                      type="date"
                      horizontal                      
                      v-model="fromDate" 
                      @input="startDateOnChange()" 
                    />            
              </CCol>
              <CCol md="5">                   
                   <CInput
                      label="To Date"
                      type="date"
                      horizontal
                      v-model="toDate" 
                      @input="toDateOnChange()" 
                    />            
              </CCol>  
              <CCol md="2">
              <b-dropdown variant="success" text="Download" size="md">
                <router-link :to='"#"' @click.native="showFormPasswd('topup')" tag="b-dropdown-item" > Topup </router-link>
                <router-link :to='"#"' @click.native="showFormPasswd('debits')" tag="b-dropdown-item" > Debits </router-link>
              </b-dropdown>
              </CCol>
              
              <!-- <CCol md="2">                 
                    <vue-excel-xlsx 
                        style="color:green"                       
                        :data="data"
                        :columns="columns"
                        :filename="'Detail Topup'"
                        :sheetname="'Topup'"
                        >
                        Download Topup
                    </vue-excel-xlsx>                 
              </CCol>
              <CCol md="2">                 
                    <vue-excel-xlsx 
                        style="color:green"                       
                        :data="data2"
                        :columns="columns2"
                        :filename="'Detail Debits'"
                        :sheetname="'Debits'"
                        >
                        Download Debits
                    </vue-excel-xlsx>                 
              </CCol>                                   -->
          </CRow>

              <CRow>
                  <CCol col="12" class="text-left">                   
                    <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
                  </CCol> 
              </CRow>  
              <CRow>
                  <CCol col="12" class="text-center">                   
                    <b-spinner variant="success" label="Spinning" v-if="spinner" ></b-spinner>
                  </CCol> 
              </CRow>   

                    <b-card-group deck class="mb-3" style="width:50%" v-if="formPasswd">  
                        <b-card border-variant="dark" header="Protected Excel Password" align="center">
                        <b-card-text>
                            <b-form-input style="text-align: center" v-model="password" size="md" :readonly="true"></b-form-input>
                        </b-card-text>                                                                      
                        <b-button-group>
                           <b-button v-on:click="actionCancel()">Cancel</b-button>
                           <b-button variant="success" v-on:click="generateWorkbook(tab)">Download</b-button>
                        </b-button-group>                                   
                        </b-card>
                     </b-card-group>
         
         <table class="table">
          <thead>
            <tr>              
              <th scope="col">Total Top-up</th>
              <th scope="col">Total Fee</th>
              <th scope="col">Total Checkout Account Debits</th>
            </tr>
          </thead>
          <tbody>
            <tr>              
              <td>{{ totalTopups | formatNum }}</td>
              <td>{{ totalFee | formatNum }}</td>
              <td>{{ totalCheckoutAccountDebits | formatNum }}</td>
            </tr>
          </tbody>
        </table><br/>
            <CTabs>
               <CTab title="Top-up" active> <br/>                

                  <div class="table-responsive">
                    <b-table striped hover  :items="items" :fields="fields" sort-by="transactionTime" sort-desc="true" :current-page="currentPage" :per-page="perPage" 
                      :filter="filter">

                      <template v-slot:cell(transactionTime)="{ item }"> 
                          {{ item.transactionTime }}
                      </template>  

                      <template v-slot:cell(amount)="{ item }"> 
                          {{ item.amount | formatNum }}
                      </template>  
                     
                    </b-table>
                  </div>

                  <div>
                    <b-row>
                      <b-col sm="3">
                         <b-pagination size="md" :total-rows="this.items.length" :per-page="perPage" v-model="currentPage" />
                      </b-col>       
                      <b-col sm="9">
                         <b-form-select :options="[{text:10,value:10},{text:20,value:20},{text:50,value:50},{text:100,value:100}]" v-model="perPage" style="width:100px;">
                         </b-form-select>
                      </b-col>
                    </b-row>      
                  </div>

                </CTab>
              <CTab title="Debits"><br/>   

                <div class="table-responsive">
                  <b-table striped hover  :items="items2" :fields="fields2" sort-by="transactionTime" sort-desc="true" :current-page="currentPage2" :per-page="perPage2" 
                    :filter="filter">

                     <template v-slot:cell(transactionTime)="{ item }"> 
                          {{ item.transactionTime }}
                     </template> 

                     <template v-slot:cell(amount)="{ item }"> 
                          {{ item.amount | formatNum }}
                      </template>   
                   
                  </b-table>
                </div>

                <div>
                  <b-row>
                    <b-col sm="3">
                       <b-pagination size="md" :total-rows="this.items2.length" :per-page="perPage2" v-model="currentPage2" />
                    </b-col>       
                    <b-col sm="9">
                       <b-form-select :options="[{text:10,value:10},{text:20,value:20},{text:50,value:50},{text:100,value:100}]" v-model="perPage2" style="width:100px;">
                       </b-form-select>
                    </b-col>
                  </b-row>      
                </div>
                
              </CTab>              
            </CTabs>        
          
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import ReportService from '@/api/ReportService.js';
import moment from 'moment';
//import Topup from './balancebytopup/topup';
//import Debit from './balancebytopup/debit';

 export default {
    name: "balancebytopup",
    components: { },     
    data() { var date = new Date();
      return {
        fromDate: new Date(date.getFullYear(), date.getMonth(), 2).toISOString().substr(0, 10),
        toDate: new Date().toISOString().substr(0, 10), 
        spinner:false,
        totalTopups:0,
        totalFee:0,
        totalCheckoutAccountDebits:0,
        list:[],
        columns : [],
        columns2 : [],
        data  : [],
        data2 : [],
        items:[], 
        items2:[],            
        fields: [ 
          {            
            key: 'transactionTime',
            label : 'Transaction Time',
            sortable: true
          },
          {           
            key: 'userId',
            label : 'User ID',
            sortable: true
          }, 
          {           
            key: 'mobile',
            label : 'Mobile Number',
            sortable: true
          }, 
          {           
            key: 'userName',
            label : 'User Name',
            sortable: true
          },
          {           
            key: 'amount',
            label : 'Amount',
            sortable: true
          }            
        ],  
        fields2: [          
          {            
            key: 'transactionTime',
            label : 'Transaction Time',
            sortable: true
          },
          {           
            key: 'amount',
            label : 'Amount',
            sortable: true
          }           
        ],         
        currentPage: 1,
        perPage: 10,
        currentPage2: 1,
        perPage2: 10,        
        filter: null,
        formPasswd:false,
        filename:"balance-by-topup-report.xlsx",
        password:"",
        tab:"topup",
        passpar:'',
        seen:false,
        msg :'',
        color:''
      };
    },    
    filters: {
        upper(value){
          if(!value) return ''
          value = value.toString()
          return value.toUpperCase()
        },
        lower(value){
           if(!value) return ''
           value = value.toString()
           return value.toLowerCase()        
        },
        dateSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,10)            
        },        
        IdSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,5) + '...'            
        },
        dateWithTime(value){
           if(!value) return ''
           var temp = value.toString().split(".");
           value = temp[0].replace("T"," ");
           return value;            
        },
        formatNum(value){ 
           if(!value) return 0  
           return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")          
        }
      },

    created () {      
       let params = {};
       this.setDefaultPasswd();
       params['fromDate'] = this.fromDate+"T00:00:00";
       params['toDate'] =  this.toDate+"T23:59:59";
       this.getBalanceByTopup(params); 
       this.setColumns('topup',this.fields);  
       this.setColumns('debits',this.fields2);   
    },
    filters: {
        dateWithTime(value){
           if(!value) return ''
           var temp = value.toString().split(".");
           value = temp[0].replace("T"," ");
           return value;            
        },
        getGeneraldate(value){
            var dt  = new Date(value);
            var dt2 = dt.toISOString();
            var temp = dt2.split(".");
            value = temp[0].replace("T"," ");
            return value;
        },
        formatNum(value){ 
           if(!value) return 0  
           return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")          
        }
    },
    methods: { 

      showFormPasswd(tab){
         this.formPasswd = true;
         this.tab = tab;
      },

      actionCancel(){
          this.formPasswd = false;
      },

      setDefaultPasswd(){
        var today = moment().format('DDMMYYYYHHmmss');
        var user  = this.$store.getters.getUser; 
        this.password = user+today;
      },     

      generateWorkbook(tab){ //console.log(tab);

         this.spinner=true; this.formPasswd = false; 
         var cols = (tab==="topup") ? this.columns : this.columns2;
         var rows = (tab==="topup") ? this.data : this.data2;  
         var filename = (tab==="topup") ? "topup-data.xlsx" : "debits-data.xlsx"; 
         this.filename = filename;

         //var cols  = this.columns; var rows  = this.data; 

         var heads = []; var temp =[]; var data=[];
         let params = {};   var colkey =[];        

          for (var key in cols) { 
             var col = cols[key]; heads.push(col.label);
             colkey.push(col.field);
          }           
          data.push(heads);  var jum = heads.length;          

          for (var key2 in rows) { var row = rows[key2]; 
              var init = Array(jum).fill(null);
              for(var key4 in colkey){
                for (var key3 in row) { 
                  var val  = row[key3];                      
                  var ckey = colkey[key4]; 
                  if(key3===ckey){  init[key4] = val; }              
                }
              }
              data.push(init); 
          } 
       
         params['password'] = this.password;
         params['data'] = data;   //console.log(params); 
         this.getReportExcel(params);
         
      },

      getReportExcel: function(params) {
        ReportService.getReportExcel(params).then(resp => {             
         try {  
            if(resp.status==200){
               var filename = this.filename;
               const link = document.createElement('a');    
               link.href = window.URL.createObjectURL(
                   new Blob([resp.data])
               );   
               link.setAttribute('download', filename);
               document.body.appendChild(link);
               link.click();  this.spinner = false; 
            } else {
              this.msg   = 'Sorry, Generated Excel Failed!'; 
              this.color = 'warning'; this.seen  = true;
              this.spinner = false; 
              setTimeout( () =>  this.seen=false , 5000);
            }
           }
         catch(err) {            
            this.msg   = 'Sorry, Generated Excel Failed!'; 
            this.color = 'warning'; this.seen  = true;
            this.spinner = false; 
            setTimeout( () =>  this.seen=false , 5000);
         } 

        }, error => { 
           this.spinner = false;            
        });
      }, 

      setDataRows(status,resp,keydate,keyamount){

          var tzoffset = (new Date()).getTimezoneOffset() * 60000;

          for (var key in resp) {                
              var resp2 = resp[key];  
                for (var key2 in resp2) { 
                    if(key2 === keydate){ 
                      var strdate = resp2[key2]; 
                      //var dt = new Date(strdate).toISOString().substr(0, 19).replace("T"," ");
                      var ts = new Date(strdate).getTime();
                      var dt = (new Date(ts-tzoffset)).toISOString().substr(0, 19).replace("T"," ");
                      resp[key][keydate] = dt; 
                    }
                    if(key2 === keyamount){ 
                       resp[key][keyamount] = resp2[key2];
                    }
                 }
            }

          if(status==='topup') { this.data  = resp; }
          if(status==='debits') { this.data2  = resp; }

      },

      formatNum(value){
         return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") 
      }, 

      dateWithTime(value){
           if(!value) return ''
           var temp = value.toString().split(".");
           value = temp[0].replace("T"," ");
           return value;            
      },   

      getFilterData(){          
         this.data  = this.list;         
      },

      setColumns(status,fields){
        let row = {}; var cols = [];
        for (var key in fields) {           
           row['label'] = fields[key].label;
           row['field'] = fields[key].key;
           var temp = JSON.stringify(row); cols.push(temp);
        }        

        for (var key2 in cols) {               
           var row2 = JSON.parse(cols[key2]); 
           if(status==='topup') { this.columns.push(row2); }
           if(status==='debits') { this.columns2.push(row2); }
        }
      },

      getRequestParams(){ 
          let params = {};
          params['fromDate'] = this.fromDate+"T00:00:00";
          params['toDate']   = this.toDate+"T23:59:59";         
          this.passpar = params;
      },

      startDateOnChange(){
        this.spinner = true;
        this.getRequestParams();
        this.getBalanceByTopup(this.passpar); 
      },

      toDateOnChange(){
        this.spinner = true;
        this.getRequestParams();
        this.getBalanceByTopup(this.passpar); 
      },    

      getBalanceByTopup: function(params) {
        ReportService.getBalanceByTopup(params).then(resp => {  //console.log(resp);

            this.totalTopups = resp.totalTopups;
            this.totalFee = resp.totalFee;
            this.totalCheckoutAccountDebits = resp.totalCheckoutAccountDebits;

            this.items   = resp.topupRecords;
            this.items2  = resp.checkoutAccountDebitRecords;

            var res1  = resp.topupRecords;
            var res2  = resp.checkoutAccountDebitRecords;

            this.setDataRows('topup',res1,'transactionTime', 'amount');
            this.setDataRows('debits',res2,'transactionTime','amount');

            this.spinner = false;

        }, error => {
           this.loading = false;
        });
      },

    }
 }; 

</script>